<template>
  <div>
    <div class="air__utils__heading">
      <div class="row">
        <div class="col-md-4">
          <h5>
            <span class="mr-3">Detail Akun</span>
          </h5>
        </div>
        <div class="col-md-8">
          <div class="d-flex flex-column justify-content-center pull-right" v-if="status == 0">
            <!-- <button class="btn btn-with-addon mr-auto btn-danger" @click="showModalReject">
                  <span class="btn-addon">
                    <i class="btn-addon-icon fe fe-user-x"></i>
                  </span>
              Tolak
            </button> -->
            <a-modal
                title="Tolak Akun"
                :visible="visible"
                :confirm-loading="confirmLoading"
                @ok="handleOkReject"
                @cancel="handleCancelReject"
            ><p>Apakah anda yakin menolak akun ini?</p>
            </a-modal>
          </div>
          <div class="d-flex flex-column justify-content-center pull-right" v-if="status == 1">
            <button class="btn btn-with-addon mr-auto btn-danger" @click="showModalDeactivate">
                  <span class="btn-addon">
                    <i class="btn-addon-icon fe fe-user-x"></i>
                  </span>
              Menonaktifkan
            </button>
            <a-modal
                title="Menonaktifkan Akun"
                :visible="visibilityDeactivate"
                :confirm-loading="confirmLoading"
                @ok="handleOkDeactivate"
                @cancel="handleCancelDeactivate"
            ><p>Apakah anda yakin menonaktifkan akun ini?</p>
            </a-modal>
          </div>
          <div class="d-flex flex-column justify-content-center mr-2 pull-right" v-if="status == 1">
            <button class="btn btn-with-addon mr-auto btn-success" @click="showModalResetPassword">
              <span class="btn-addon">
                <i class="btn-addon-icon fe fe-refresh-cw"></i>
              </span>
              Reset Password
            </button>
            <a-modal
                title="Reset Password"
                :visible="visibilityResetPassword"
                :confirm-loading="confirmLoading"
                @ok="handleOkResetPassword"
                @cancel="handleCancelResetPassword"
            ><p>Apakah anda yakin menonaktifkan akun ini?</p>
            </a-modal>
          </div>
          <div class="d-flex flex-column justify-content-center mr-2 pull-right" v-if="status == 2 || status == 0">
            <button class="btn btn-with-addon mr-auto btn-danger" @click="showModalDeleteAccount">
              <span class="btn-addon"><i class="btn-addon-icon fe fe-trash"></i></span>
              Hapus
            </button>
            <a-modal
                title="Hapus Permanen Akun"
                :visible="visibilityDelete"
                :confirm-loading="confirmLoading"
                @ok="handleOkeDeleteAccount"
                @cancel="handleCancelDeleteAccount"
            ><p>Apakah anda yakin menghapus permanen akun ini?</p>
            </a-modal>
          </div>
          <div class="d-flex flex-column justify-content-center mr-2 pull-right" v-if="status == 2 || status == 0">
            <button class="btn btn-with-addon mr-auto btn-success" @click="showModalActivateAccount">
              <span class="btn-addon"><i class="btn-addon-icon fe fe-send"></i></span>
              Aktivasi
            </button>
            <a-modal
                title="Aktivasi Akun"
                :visible="visibilityActivate"
                :confirm-loading="confirmLoading"
                @ok="handleOkActivateAccount"
                @cancel="hhandleCancelActivateAccount"
            ><p>Apakah anda yakin aktivasi akun ini?</p>
            </a-modal>
          </div>
          <div class="d-flex flex-column justify-content-center mr-2 pull-right" v-if="status == 0">
            <button class="btn btn-with-addon mr-auto btn-success" @click="resendInvitation">
              <span class="btn-addon"><i class="btn-addon-icon fe fe-send"></i></span>
              Kirim Ulang Undangan
            </button>
            <a-modal
                title="Terima Akun"
                :visible="visible2"
                :confirm-loading="confirmLoading"
                @ok="handleOkay"
                @cancel="handleCanceled"
            >
              <a-form layout='vertical' :form="formApproval" @submit="approveData">
                <a-form-item label='Role' class="col-md-12">
                  <a-select v-decorator="[
                      'role',
                  {
                            rules: [
                              {
                                required: true,
                                message: 'Field ini tidak boleh kosong'
                              },
                            ]
                          }]"
                            placeholder="Pilih Role"
                            style="width: 100%"
                            @change="handleChange"
                  >
                    <a-select-option value=0>
                      Admin
                    </a-select-option>
                    <a-select-option value=1>
                      Staff
                    </a-select-option>
                    <a-select-option value=2>
                      Admin Pengguna
                    </a-select-option>
                    <a-select-option value=3>
                      Admin Konten
                    </a-select-option>
                  </a-select>
                </a-form-item>
                <a-form-item label="Group" class="col-md-12">
                  <a-select
                      show-search
                      placeholder="Pilih Group"
                      style="width: 100%"
                      :default-active-first-option="false"
                      :show-arrow="false"
                      :filter-option="false"
                      :not-found-content="null"
                      @search="handleSearchGroupTag"
                      v-decorator="['groups']"
                  >
                    <a-select-option
                        v-for="group in groups"
                        :key="group.name">
                      {{ group.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-form>
            </a-modal>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <a-form :form="form" @submit="handleSubmit">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <a-form-item label="Nomor Induk Kepegawaian">
                    <a-input
                        placeholder="Ex: 1234567890123456"
                        v-bind:disabled="true"
                        v-decorator="[
                        'employee_number',
                      ]"
                    />
                  </a-form-item>
                  <a-form-item label="Nama Depan">
                    <a-input
                        placeholder="Ex: Jhon"
                        v-bind:disabled="flag"
                        v-decorator="[
                        'firstname',
                        {
                          rules:[
                              {
                                required: true,
                                message: 'Nama depan tidak boleh kosong'
                              },
                              {
                                min: 3,
                                message: 'Nama depan minimal 3 karakter'
                              },
                              {
                                max: 20,
                                message: 'Nama depan maximal 20 karakter'
                              }
                          ]
                        }
                      ]"
                    />
                  </a-form-item>
                  <a-form-item label="Nama Belakang">
                    <a-input
                        placeholder="Ex: Doe"
                        v-bind:disabled="flag"
                        v-decorator="[
                        'lastname',
                        {
                          rules:[
                              {
                                min: 3,
                                message: 'Nama belakang minimal 3 karakter'
                              },
                              {
                                max: 20,
                                message: 'Nama belakang maximal 20 karakter'
                              }
                          ]
                        }
                      ]"
                    />
                  </a-form-item>
                  <a-form-item label="E-mail">
                    <a-input
                        placeholder="Ex: jhondoe@gmail.com"
                        v-bind:disabled="true"
                        v-decorator="[
                        'email',
                        {
                          rules: [
                            {
                              type: 'email',
                              message: 'Email tidak sesuai!',
                            },
                            {
                              required: true,
                              message: 'Email tidak boleh kosong!'
                            }
                          ]
                        }
                      ]"
                    />
                  </a-form-item>
                  <a-form-item label='Role'>
                    <a-select
                        placeholder="Pilih Role"
                        v-bind:disabled="flag"
                        v-decorator="[
                      'role',
                      {
                            rules: [
                              {
                                required: true,
                                message: 'Field ini tidak boleh kosong'
                              },
                            ]
                      }
                    ]"
                        @change="handleChange"
                    >
                      <a-select-option value=0>
                        Super Admin
                      </a-select-option>
                      <a-select-option value=1>
                        Staff
                      </a-select-option>
                      <a-select-option value=2>
                        Admin Pengguna
                      </a-select-option>
                      <a-select-option value=3>
                        Admin Konten
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                  <a-form-item label="Status">
                    <a-select
                        placeholder="Pilih Status"
                        v-bind:disabled="flag"
                        v-decorator="[
                      'status',
                      {
                            rules: [
                              {
                                required: true,
                                message: 'Field ini tidak boleh kosong'
                              },
                            ]
                      }
                    ]"
                        @change="handleChange"
                    >
                      <a-select-option value=0>
                        Baru
                      </a-select-option>
                      <a-select-option value=1>
                        Aktif
                      </a-select-option>
                      <a-select-option value=2>
                        Tidak aktif
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                  <a-form-item label="Jenis kelamin">
                    <a-select
                        placeholder="Pilih jenis kelamin"
                        v-bind:disabled="flag"
                        v-decorator="[
                      'gender',
                      // {
                      //   rules: [
                      //         {
                      //           required: true,
                      //           message: 'Field ini tidak boleh kosong'
                      //         },
                      //       ]
                      // }
                    ]"
                    >
                      <a-select-option value=1>
                        Pria
                      </a-select-option>
                      <a-select-option value=2>
                        Wanita
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                  <a-form-item label="Tempat lahir">
                    <a-input
                        placeholder="Ex: Jakarta"
                        v-bind:disabled="flag"
                        v-decorator="[
                        'pob',
                        {
                          rules:[
                              {
                                min: 2,
                                message: 'Tempat lahir minimal 2 karakter'
                              },
                              {
                                max: 20,
                                message: 'Tempat lahir maximal 20 karakter'
                              }
                          ]
                        }
                      ]"
                    />
                  </a-form-item>
                  <a-form-item label='Tanggal lahir'>
                    <a-date-picker
                        v-bind:disabled="flag"
                        :disabled-date="disabledDate"
                        v-decorator="[
                        'dob',
                      ]"
                    />
                  </a-form-item>
                  <a-form-item label="No HP">
                    <a-input
                        placeholder=" Ex: 0822xxxxxxxx"
                        v-bind:disabled="flag"
                        v-decorator="[
                        'mobile_no',
                        {
                          rules:[
                              {
                                min: 3,
                                message: 'Nomor HP minimal 3 karakter'
                              },
                              {
                                max: 20,
                                message: 'Nomor HP maximal 20 karakter'
                              }
                          ]
                        }
                      ]"
                    />
                  </a-form-item>
                  <a-form-item label="No Telepon">
                    <a-input
                        placeholder="Ex: 021xxxxxxxx"
                        v-bind:disabled="flag"
                        v-decorator="[
                        'phone_no',
                        {
                          rules:[
                              {
                                min: 3,
                                message: 'Nomor telepon minimal 3 karakter'
                              },
                              {
                                max: 20,
                                message: 'Nomor telepon maximal 20 karakter'
                              }
                          ]
                        }
                      ]"
                    />
                  </a-form-item>
                  <a-form-item label="Status perkawinan">
                    <a-select
                        placeholder="Pilih status perkawinan"
                        v-bind:disabled="flag"
                        v-decorator="[
                      'marital_status',
                      // {
                      //   rules: [
                      //         {
                      //           required: true,
                      //           message: 'Field ini tidak boleh kosong'
                      //         },
                      //       ]
                      // }
                    ]"
                    >
                      <a-select-option value=1>
                        Belum kawin
                      </a-select-option>
                      <a-select-option value=2>
                        Kawin
                      </a-select-option>
                      <a-select-option value=3>
                        Cerai mati
                      </a-select-option>
                      <a-select-option value=4>
                        Cerai hidup
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                  <a-form-item label="Agama">
                    <a-select
                        placeholder="Pilih agama"
                        v-bind:disabled="flag"
                        v-decorator="[
                      'religion',
                      // {
                      //   rules: [
                      //         {
                      //           required: true,
                      //           message: 'Field ini tidak boleh kosong'
                      //         },
                      //       ]
                      // }
                    ]"
                    >
                      <a-select-option value=1>
                        Islam
                      </a-select-option>
                      <a-select-option value=2>
                        Katolik
                      </a-select-option>
                      <a-select-option value=3>
                        Kristen Protestan
                      </a-select-option>
                      <a-select-option value=4>
                        Buddha
                      </a-select-option>
                      <a-select-option value=5>
                        Hindu
                      </a-select-option>
                      <a-select-option value=6>
                        Khonghucu
                      </a-select-option>
                      <a-select-option value=7>
                        Lainnya
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                  <a-form-item label="Kartu identitas">
                    <a-select
                        placeholder="Pilih kartu identitas"
                        v-bind:disabled="flag"
                        @change="changeIdCardFlag"
                        v-decorator="[
                      'id_type',
                      // {
                      //   rules: [
                      //         {
                      //           required: true,
                      //           message: 'Field ini tidak boleh kosong'
                      //         },
                      //       ]
                      // }
                    ]"
                    >
                      <a-select-option value=1>
                        KTP
                      </a-select-option>
                      <a-select-option value=2>
                        SIM
                      </a-select-option>
                      <a-select-option value=3>
                        Passport
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                  <a-form-item label="Nomor Induk Kependudukan">
                    <a-input
                        placeholder="Ex: 1234567890123456"
                        v-bind:disabled="flag"
                        v-decorator="[
                        'id_number',
                        {
                          rules:[
                              {
                                required: idCardFlag,
                                message: 'Field ini tidak boleh kosong'
                              },
                              {
                                min: 3,
                                message: 'Nomor induk kependudukan minimal 3 karakter'
                              },
                              {
                                max: 20,
                                message: 'Nomor induk kependudukan maximal 20 karakter'
                              }
                          ]
                        }
                      ]"
                    />
                  </a-form-item>
                  <a-form-item label="Berlaku hingga">
                    <a-input
                        placeholder="Sementara"
                        v-bind:disabled="flag"
                        v-decorator="[
                        'id_exp_date',
                        {
                          rules:[
                              {
                                required: idCardFlag,
                                message: 'Field ini tidak boleh kosong'
                              },
                              {
                                min: 3,
                                message: 'Masa berlaku minimal 3 karakter'
                              },
                              {
                                max: 20,
                                message: 'Masa berlaku maximal 20 karakter'
                              }
                          ]
                        }
                      ]"
                    />
                  </a-form-item>
                  <a-form-item label="Alamat">
                    <a-input
                        placeholder="Ex: Jakarta"
                        v-bind:disabled="flag"
                        v-decorator="[
                        'address',
                        {
                          rules:[
                              {
                                required: idCardFlag,
                                message: 'Field ini tidak boleh kosong'
                              },
                          ]
                        }
                      ]"
                    />
                  </a-form-item>
                  <a-form-item label="Kode Pos">
                    <a-input
                        placeholder="Ex: 13899"
                        v-bind:disabled="flag"
                        v-decorator="[
                        'id_zip_code',
                        {
                          rules:[
                              // {
                              //   required: true,
                              //   message: 'Field ini tidak boleh kosong'
                              // },
                              {
                                min: 3,
                                message: 'Kode pos minimal 3 digit'
                              },
                              {
                                max: 10,
                                message: 'Kode pos maximal 10 digit'
                              }
                          ]
                        }
                      ]"
                    />
                  </a-form-item>
                  <a-form-item label="Golongan darah">
                    <a-select
                        placeholder="Pilih golongan darah"
                        v-bind:disabled="flag"
                        v-decorator="[
                      'blood_type',
                      // {
                      //   rules:[
                      //       {
                      //           required: true,
                      //           message: 'Field ini tidak boleh kosong'
                      //         },
                      //   ]
                      // }
                    ]"
                    >
                      <a-select-option value=1>
                        A
                      </a-select-option>
                      <a-select-option value=2>
                        B
                      </a-select-option>
                      <a-select-option value=3>
                        AB
                      </a-select-option>
                      <a-select-option value=4>
                        O
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                  <a-form-item label="Group">
                    <a-select
                        placeholder="Pilih Group"
                        mode="multiple"
                        style="width: 100%"
                        :default-active-first-option="false"
                        :show-arrow="true"
                        :filter-option="false"
                        :not-found-content="null"
                        @search="handleSearchGroupTag"
                        v-bind:disabled="flag"
                        v-decorator="['groups']"
                    >
                      <a-select-option
                          v-for="group in groups"
                          :key="group.name">
                        {{ group.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                  <a-form-item label="Posisi">
                    <a-input
                        placeholder="Ex: General Manager"
                        v-bind:disabled="flag"
                        v-decorator="[
                        'position',
                        {
                          rules:[
                              {
                                max: 100,
                                message: 'Posisi maximal 100 karakter'
                              }
                          ]
                        }
                      ]"
                    />
                  </a-form-item>
                  <a-form-item label="Penempatan">
                    <a-input
                        placeholder="Ex: Jakarta"
                        v-bind:disabled="flag"
                        v-decorator="[
                        'placement',
                        {
                          rules:[
                              {
                                max: 100,
                                message: 'Penempatan maximal 100 karakter'
                              }
                          ]
                        }
                      ]"
                    />
                  </a-form-item>
                </div>
                <div class="col-md-12">
                  <button type="submit" class="btn btn-primary btn-with-addon" v-bind:disabled="flag">
                    <span class="btn-addon">
                      <i class="btn-addon-icon fe fe-send"/>
                    </span>
                    Simpan
                  </button>
                </div>
              </div>
            </div>
          </div>
        </a-form>
      </div>
    </div>
  </div>
</template>

<script>
import {
  activateAccount,
  bannedAccount,
  deleteAccount,
  permanentDeleteAccount,
  editPersonalInformation,
  getDetailAccount,
  inactivatedAccount,
  resendInvitation,
  resetPassword
} from "@/api/account.api";
import {getListGroup} from "@/api/group.api";
import moment from 'moment'

export default {
  props: {
    id: String
  },
  data() {
    return {
      form: this.$form.createForm(this),
      formApproval: this.$form.createForm(this),
      visible: false,
      visible2: false,
      visibilityActivate: false,
      visibilityDeactivate: false,
      visibilityResetPassword: false,
      visibilityDelete: false,
      confirmLoading: false,
      groups: [],
      status: '',
      flag: true,
      IdAddress: '',
      idCardFlag: false,
      email: ''
    }
  },
  computed: {},
  mounted() {
    this.fetchDetail()
  },
  methods: {
    resendInvitation() {
      this.$nprogress.start();
      resendInvitation({ email: this.email }).then(() => {
        this.$nprogress.done();
        this.$notification["success"]({
          message: "Sukses",
          description: "Undangan berhasil dikirim ulang"
        });
      })
    },
    showModalDeactivate() {
      this.visibilityDeactivate = true;
    },
    showModalDeleteAccount() {
      this.visibilityDelete = true;
    },
    showModalActivateAccount() {
      this.visibilityActivate = true;
    },
    showModalResetPassword(){
      this.visibilityResetPassword = true;
    },
    handleOkDeactivate() {
      this.confirmLoading = true;
      this.deactivatedAccount();
      setTimeout(() => {
        this.visibilityDeactivate = false;
        this.confirmLoading = false;
      }, 2000);
    },
    handleOkeDeleteAccount() {
      this.confirmLoading = true;
      const deleteFunc = this.status == 0 ? permanentDeleteAccount : deleteAccount;
      deleteFunc(this.id).then(() => {
        this.visibilityDelete = false;
        this.confirmLoading = false;
        this.$router.push("/account-management");
        this.$notification["success"]({
          message: "Sukses",
          description: "Akun berhasil dihapus"
        });
      }).catch(() => {
        this.visibilityDelete = false;
        this.confirmLoading = false;
      })
    },
    handleOkActivateAccount() {
      this.confirmLoading = true;
      activateAccount(null, this.id).then(() => {
        this.visibilityActivate = false;
        this.confirmLoading = false;
        this.$router.push("/account-management");
        this.$notification["success"]({
          message: "Sukses",
          description: "Akun berhasil diaktivasi"
        });
      }).catch(() => {
        this.visibilityDelete = false;
        this.confirmLoading = false;
      })
    },
    handleOkResetPassword() {
      this.confirmLoading = true;
      this.resetPasswordAccount();
    },
    handleCancelDeactivate() {
      this.visibilityDeactivate = false;
    },
    handleCancelDeleteAccount() {
      this.visibilityDelete = false;
    },
    hhandleCancelActivateAccount() {
      this.visibilityActivate = false;
    },
    handleCancelResetPassword() {
      this.visibilityResetPassword = false;
    },
    showModalReject() {
      this.visible = true;
    },
    handleOkReject() {
      this.confirmLoading = true;
      this.rejectData();
      setTimeout(() => {
        this.visible = false;
        this.confirmLoading = false;
      }, 2000);
    },
    handleCancelReject() {
      this.visible = false;
    },
    showModalApproval() {
      this.visible2 = true;
    },
    handleOkay(e) {
      this.approveData(e);
    },
    handleCanceled() {
      this.visible2 = false;
      this.formApproval.resetFields()
    },
    async fetchDetail() {
      await getDetailAccount(this.id).then((response) => {
        if (!response) {
          this.$router.push('/account-management')
        }
        this.email = response.data.email
        this.status = response.data.status
        this.IdAddress = response.data.id_address;
        if(response.data.status != 0 && response.data.status != 3 && response.data.status !=2){
          this.flag = false;
        }
        switch (response.data.role) {
          case 0:
            this.form.setFieldsValue({role: (response.data.role ? '0' : 'Super Admin')});
            break;
          case 1:
            this.form.setFieldsValue({role: (response.data.role ? '1' : 'Staff')});
            break;
          case 2:
            this.form.setFieldsValue({role: (response.data.role ? '2' : 'Admin Pengguna')});
            break;
          default:
            this.form.setFieldsValue({role: (response.data.role ? '3' : 'Admin Content')});
            break;
        }
        switch (response.data.status) {
          case 0:
            this.form.setFieldsValue({status: (response.data.status ? '0' : 'Baru')});
            break;
          case 1:
            this.form.setFieldsValue({status: (response.data.status ? '1' : 'Aktif')});
            break;
          case 2:
            this.form.setFieldsValue({status: (response.data.status ? '2' : 'Tidak aktif')});
            break;
          default:
            this.form.setFieldsValue({status: (response.data.status ? '3' : 'Ditolak')});
            break;
        }
        switch (response.data.gender) {
          case 1:
            this.form.setFieldsValue({gender: (response.data.gender ? '1' : 'Pria')});
            break;
          case 2:
            this.form.setFieldsValue({gender: (response.data.gender ? '2' : 'Wanita')});
            break;
          default:
            this.form.setFieldsValue({gender: undefined});
            break;
        }
        switch (response.data.marital_status) {
          case 1:
            this.form.setFieldsValue({marital_status: (response.data.marital_status ? '1' : 'Belum kawin')});
            break;
          case 2:
            this.form.setFieldsValue({marital_status: (response.data.marital_status ? '2' : 'Kawin')});
            break;
          case 3:
            this.form.setFieldsValue({marital_status: (response.data.marital_status ? '3' : 'Cerai mati')});
            break;
          case 4:
            this.form.setFieldsValue({marital_status: (response.data.marital_status ? '4' : 'Cerai hidup')});
            break;
          default:
            this.form.setFieldsValue({marital_status: undefined});
            break;
        }
        switch (response.data.religion) {
          case 1:
            this.form.setFieldsValue({religion: (response.data.religion ? '1' : 'Islam')});
            break;
          case 2:
            this.form.setFieldsValue({religion: (response.data.religion ? '2' : 'Katolik')});
            break;
          case 3:
            this.form.setFieldsValue({religion: (response.data.religion ? '3' : 'Kristen protestan')});
            break;
          case 4:
            this.form.setFieldsValue({religion: (response.data.religion ? '4' : 'Buddha')});
            break;
          case 5:
            this.form.setFieldsValue({religion: (response.data.religion ? '5' : 'Hindu')});
            break;
          case 6:
            this.form.setFieldsValue({religion: (response.data.religion ? '6' : 'Khonghucu')});
            break;
          case 7:
            this.form.setFieldsValue({religion: (response.data.religion ? '7' : 'Lainnya')});
            break;
          default:
            this.form.setFieldsValue({religion: undefined});
            break;
        }
        switch (response.data.id_type) {
          case 1:
            this.form.setFieldsValue({id_type: (response.data.id_type ? '1' : 'KTP')});
            break;
          case 2:
            this.form.setFieldsValue({id_type: (response.data.id_type ? '2' : 'SIM')});
            break;
          case 3:
            this.form.setFieldsValue({id_type: (response.data.id_type ? '3' : 'Passport')});
            break;
          default:
            this.form.setFieldsValue({id_type: undefined});
            break;
        }
        switch (response.data.blood_type) {
          case 1:
            this.form.setFieldsValue({blood_type: (response.data.blood_type ? '1' : 'A')});
            break;
          case 2:
            this.form.setFieldsValue({blood_type: (response.data.blood_type ? '2' : 'B')});
            break;
          case 3:
            this.form.setFieldsValue({blood_type: (response.data.blood_type ? '3' : 'AB')});
            break;
          case 4:
            this.form.setFieldsValue({blood_type: (response.data.blood_type ? '4' : 'O')});
            break;
          default:
            this.form.setFieldsValue({blood_type: undefined});
            break;
        }
        this.form.setFieldsValue({employee_number: response.data.employee_number});
        this.form.setFieldsValue({firstname: response.data.firstname});
        if(response.data.lastname !== 'null' && response.data.lastname !== 'undefined'){
          this.form.setFieldsValue({lastname: response.data.lastname});
        }
        this.form.setFieldsValue({fullname: response.data.fullname});
        this.form.setFieldsValue({email: response.data.email});
        if(response.data.pob !== 'null' && response.data.pob !== 'undefined'){
          this.form.setFieldsValue({pob: response.data.pob});
        }
        if (response.data.dob != null) {
          this.form.setFieldsValue({dob: moment(String(response.data.dob))});
        }
        if(response.data.mobile_no !== 'null' && response.data.mobile_no !== 'undefined'){
          this.form.setFieldsValue({mobile_no: response.data.mobile_no});
        }
        if (response.data.phone_no !== 'null' && response.data.phone_no !== 'undefined'){
          this.form.setFieldsValue({phone_no: response.data.phone_no});
        }
        if(response.data.id_number !== 'undefined' && response.data.id_number !== 'null'){
          this.form.setFieldsValue({id_number: response.data.id_number});
        }
        if(response.data.id_exp_date !== 'null' && response.data.id_exp_date !== 'undefined'){
          this.form.setFieldsValue({id_exp_date: response.data.id_exp_date});
        }
        if(response.data.id_zip_code !== 'null' && response.data.id_zip_code !== 'undefined'){
          this.form.setFieldsValue({id_zip_code: response.data.id_zip_code});
        }
        if(response.data.address !== 'null' && response.data.address !=='undefined'){
          this.form.setFieldsValue({address: response.data.address});
        }
        let selected_groups = [];
        response.data.groups.forEach(element => {
          selected_groups.push(element.name);
        });
        this.form.setFieldsValue({groups: selected_groups});
        if(response.data.position !== 'null' && response.data.position!== 'undefined'){
          this.form.setFieldsValue({position: response.data.position});
        }
        if(response.data.placement !== 'null' && response.data.placement !== 'undefined'){
          this.form.setFieldsValue({placement: response.data.placement});
        }
      })
    },
    async rejectData() {
      try {
        let response = await bannedAccount(this.id)
        if (response) {
          this.$notification["success"]({
            message: "Sukses",
            description: "Akun berhasil ditolak"
          });
          await this.$router.push("/account-management")
        }
      } catch (error) {/**/
      }
    },
    async deactivatedAccount() {
      try {
        let response = await inactivatedAccount(this.id)
        if (response) {
          this.$notification["success"]({
            message: "Sukses",
            description: "Akun berhasil dinonaktifkan"
          });
          this.$router.go(this.$router.currentRoute)
        }
      } catch (error) {/**/
      }
    },
    async resetPasswordAccount() {
      try {
        let response = await resetPassword(this.id)
        if (response) {
          this.$notification["success"]({
            message: "Sukses",
            description: "Password berhasil diperbarui"
          });
          await this.$router.push("/account-management")
        }
      } catch (error) {/**/
      } finally {
        this.visibilityResetPassword = false;
        this.confirmLoading = false;
      }
    },
    async acceptData(param) {
      try {
        let response = await activateAccount(param, this.id)
        if (response) {
          this.$notification["success"]({
            message: "Sukses",
            description: "Akun berhasil diaktivasi"
          });
          await this.$router.push("/account-management")
        }
      } catch (error) {/**/
        this.confirmLoading = false;
      }
      this.$nprogress.done()
    },
    approveData(e) {
      e.preventDefault();
      this.formApproval.validateFields((err, value) => {
        let group = "";
        if (value.groups != undefined) {
          group = JSON.stringify(value.groups).toString();
        }
        if (!err) {
          this.$nprogress.start();
          let formData = new FormData();
          formData.append("role", value.role.toString());
          if (group != undefined) {
            formData.append("groups", group.substring(1, group.length - 1).replace(/["']/g, ""));
          }
          // for (let assign of formData.values()) {
          //   console.log(assign);
          // }
          this.acceptData(formData);
          this.confirmLoading = true;
          setTimeout(() => {
            this.visible2 = false;
            this.confirmLoading = false;
            this.formApproval.resetFields()
          }, 2000);
        }
      })
    },
    async handleSearchGroupTag(value) {
      if (value.length >= 1) {
        try {
          let params = {
            limit: 200,
            search: value,
            sort: 'desc'
          };
          let result = await getListGroup(params);
          this.groups = result.data.results;
        } catch (error) {/**/
        }
      }
    },
    // handleChangeGroup(value){
    //   console.log(value)
    // },
    handleChange() {
    },
    disabledDate(current) {
      // Can not select days after today and today
      let dt = new Date();
      return current && current > Date.UTC(dt.getFullYear(), dt.getMonth(), dt.getDate());
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, value) => {
        const group = JSON.stringify(value.groups).toString();
        if (!err) {
          this.$nprogress.start();
          let formData = new FormData();
          formData.set("employee_number", value.employee_number);
          formData.set("firstname", value.firstname);
          formData.set("lastname", value.lastname);
          formData.set("email", value.email);
          if (value.password != null) {
            formData.set("password", value.password);
          }
          if(value.role !=='Super Admin'){
            formData.set("role", value.role);
          }else {
            formData.set("role", 0);
          }
          formData.set("status", value.status);
          if(value.gender !== undefined){
            formData.set("gender", value.gender);
          }
          formData.set("pob", value.pob);
          if(value.dob != undefined){
            formData.set("dob", value.dob.format('YYYY-MM-DD'));
          }
          formData.set("mobile_no", value.mobile_no);
          formData.set("phone_no", value.phone_no);
          if(value.marital_status !== undefined){
            formData.set("marital_status", value.marital_status);
          }
          if(value.religion !== undefined){
            formData.set("religion", value.religion);
          }
          if(value.id_type){
            formData.set("id_type", value.id_type);
          }
          if(value.id_number !== null){
            formData.set("id_number", value.id_number);
          }
          formData.set("id_exp_date", value.id_exp_date);
          if(value.address !==''){
            formData.set("id_address", value.address);
          }else if(this.IdAddress !== null || this.IdAddress !=='null'){
            formData.set("id_address", this.IdAddress);
          }else {
            formData.set("id_address", null);
          }
          formData.set("id_zip_code", value.id_zip_code);
          formData.set("address", value.address);
          if(value.blood_type){
            formData.set("blood_type", value.blood_type);
          }
          formData.set("groups", group.substring(1, group.length - 1).replace(/["']/g, ""));
          formData.set("position", value.position);
          formData.set("placement", value.placement);
          this.submitPersonalInformation(formData);
        }
      })
    },
    submitPersonalInformation(params) {
      try {
        editPersonalInformation(params, this.id).then(
            (response) => {
              if (response.status == 200) {
                this.$notification["success"]({
                  message: "Sukses",
                  description: "Berhasil memperbaharui Personal Information"
                });
                this.$router.push("/account-management");
              }
            }
        );
      } catch (error) {
        /**/
      }
      this.$nprogress.done();
    },
    changeIdCardFlag(e){
      if(e !==0){
        this.idCardFlag = false;
      }
    },
  }
}
</script>

<style scoped>

</style>
